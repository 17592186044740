<template>
    <div class="sup_content sup_role_management">
        <!--- Create New Role --->
        <div class="sup_page_heading">
            <h2>Role Management</h2>
            <Button v-if="roleAdd" icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add Role'" @click="roleModal.addRoleModal = true" />
        </div>
        <!--- Show Role Content --->
        <div class="sup_inner_content">
            <div class="sup_cate_list_wrap">
                <!--- Show Role Table Start --->
                <DataTable class="p-datatable" :value="allRoleData.data" :paginator="true" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}"  :rowsPerPageOptions="[10,20,50]" removableSort responsiveLayout="scroll" :rowHover="true" :loading="allRoleData.loading">
                    <template #loading>
                        Loading customers data. Please wait.
                    </template>
                    <template #header>
                        <div class="p-d-flex p-jc-between">
                            <div class="sup_list_action">
                                <Button v-if="roleDeactivateList" :label="allRoleData.roleStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allRoleData.roleStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allRoleData.roleStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-button-sm']" @click="fetchAllRole(allRoleData.roleStatus, 'roleStatus')" />
                            </div>
                            <div class="p-d-flex p-align-center">
                                <span class="p-input-icon-left sup_search_box p-mr-2">
                                    <i class="pi pi-search" />
                                    <InputText v-model="allRoleData.searchRole" placeholder="Search Role" @keyup.enter="fetchAllRole(allRoleData.roleStatus, 'search')"/>
                                </span>
                                <Button v-if="allRoleData.searchRole !== null" icon="pi pi-refresh" class="p-button-sm sup_search_clear" @click="fetchAllRole(allRoleData.roleStatus, 'searchClear')"/>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        No Role found.
                    </template>
                    <Column field="description" header="Role" :sortable="true"></Column>
                    <Column field="menuAccess" header="Primary Menu Access">
                        <template #body="slotProps">
                            <Button v-if="pagePrivilages.userRole === 'systemAdministrator' ? slotProps.data.status : pagePrivilages.actions.rolemanagementEdit ? slotProps.data.status : false" type="button" :label="Object.keys(slotProps.data.menuAccess).length > 1 ? 'Active Menus' : 'Active Menu'" :badge="Object.keys(slotProps.data.menuAccess).length" class="p-button-sm p-button-primary p-button-rounded" @click="editRole(slotProps.data, 'roleMenuEdit')" />
                            <span v-else>First Restore Role</span>
                        </template>
                    </Column>
                    <Column field="User" header="Managed By">
                        <template #body="slotProps">
                            {{slotProps.data.User.name}}
                        </template>
                    </Column>
                    <Column field="status" header="Role Status">
                        <template #body="slotProps">
                            <div :class="['sup_role_status', slotProps.data.status === true ? 'sup_role_status_active' : 'sup_role_status_deactive']">
                                <i :class="['pi', slotProps.data.status === true ? 'pi-check' : 'pi-times']" />{{ slotProps.data.status === true ? 'Active' : 'Deactive' }}
                            </div>
                        </template>
                    </Column>
                    <Column field="action" header="Actions">
                        <template #body="slotProps">
                            <Button v-if="roleEdit ? slotProps.data.status : false" icon="pi pi-pencil" class="p-button-primary p-button-rounded p-button-sm p-mr-1" @click="editRole(slotProps.data, 'fullRoleEdit')" v-tooltip="'Edit Role'" />
                            <Button v-if="roleDeactivate ? slotProps.data.status : false" icon="pi pi-ban" class="p-button-danger p-button-rounded p-button-sm" @click="confimationModal(slotProps.data, 'delete')" v-tooltip="'Deactivate Role'" />
                            <Button v-if="!slotProps.data.status" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm" @click="confimationModal(slotProps.data, 'restore')" v-tooltip="'Restore Role'" />
                        </template>
                    </Column>
                </DataTable>
                <!--- Show Role Table End --->
            </div>
            <!--- Add Role Modal Start --->
            <Dialog header="Add New Role" v-model:visible="roleModal.addRoleModal" :modal="true" :style="{width: '600px'}">
                <form class="sup_role_add" @submit.prevent="createRole()">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label for="roleName">Role</label>
                            <InputText v-model="addEditRole.roleName" id="roleName" type="text" placeholder="ex: billingManager" />
                            <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.rolenameEmpty">Role is not available.</small>
                            <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.rolenameinvalid">Provide role name in a valid fromat.</small>
                        </div>
                        <div class="p-field p-col-6">
                            <label for="roleDesc">Role Name</label>
                            <InputText v-model="addEditRole.roleDesc" id="roleDesc" type="text" placeholder="ex: Billing Manager" />
                            <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.roleDescError">Role Name is not available.</small>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12">
                            <label for="menuItems">Menu Access</label>
                            <MultiSelect v-model="addEditRole.menuItems" id="menuItems" :options="allMenuData.menu" optionLabel="name" placeholder="Assign Menu" @hide="fetchAllMenuPrivilege()" />
                            <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.roleMenuError">Assign atleast a Menu items.</small>
                            <div class="sup_menu_given">
                                <h4>Active Menus</h4>
                                <ul>
                                    <li v-for="(menu, i) of addEditRole.menuItems" :key="i">{{menu.name}}</li>
                                </ul>
                                <span v-if="addEditRole.menuItems === ''">No Menu Assigned</span>
                            </div>
                            <label v-if="allMenuData.privilege" for="privilegetems" class="p-mt-2">Menu Privilege Access</label>
                            <MultiSelect v-if="allMenuData.privilege != null" v-model="addEditRole.menuPrivilegeItems" id="privilegeItems" :options="allMenuData.privilege" optionLabel="label" optionGroupLabel="value" optionGroupChildren="items" />
                            <div v-if="allMenuData.privilege" class="sup_menu_given">
                                <h4>Active Menus Privilege</h4>
                                <ul>
                                    <li v-for="(privilege, i) of addEditRole.menuPrivilegeItems" :key="i">{{privilege.value}}</li>
                                </ul>
                                <span v-if="addEditRole.menuPrivilegeItems === ''">No Privileges AssignedAssign</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-fluid ">
                        <Button type="submit" label="Add" class="btn-primary" />
                    </div>
                    <div :class="['sup_form_massage p-mt-3', addEditRole.addStatus === 200 ? 'success' : 'error']" v-if="addEditRole.addStatus !== ''">{{addEditRole.addMessage}}</div >
                </form>
            </Dialog>
            <!--- Add Role Modal End --->
            <!--- Edit Role Modal Start 'fullRoleEditModal' --->
            <Dialog v-model:visible="roleModal.editRoleModal" :style="{width: '600px'}" :modal="true" @hide="closeModal()">
                <template #header>
                    <span class="p-dialog-title">Edit {{modalRoledata.value.description}} Role</span>
                </template>
                <form class="sup_role_edit" @submit.prevent="updateRole(null, 'fullEditsubmit')">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label for="firstname">Role</label>
                            <InputText v-model="addEditRole.roleName" id="roleName" type="text" placeholder="ex: billingManager" readonly />
                            <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.rolenameError">Role is not available or in a wrong fromat.</small>
                        </div>
                        <div class="p-field p-col-6">
                            <label for="lastname">Name</label>
                            <InputText v-model="addEditRole.roleDesc" id="roleDesc" type="text" placeholder="ex: Billing Manager" />
                            <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.roleDescError">Role Name is not available.</small>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12">
                            <label for="fullMenuEdit">Menu Access</label>
                            <MultiSelect v-model="addEditRole.menuItems" id="fullMenuEdit" :options="allMenuData.menu" optionLabel="name" placeholder="Add or Remove menu" @hide="fetchAllMenuPrivilege(), fetchAllMenuPrivilege()" @change="getchangedMenu($event)" />
                            <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.roleMenuError">Assign atleast a Menu items.</small>
                            <div class="sup_menu_given">
                                <h4>Active Menus</h4>
                                <ul>
                                    <li v-for="(menu, i) of addEditRole.menuItems" :key="i">{{menu.name}}</li>
                                </ul>
                                <span v-if="addEditRole.menuItems === ''">Assign Menu</span>
                            </div>
                            <label v-if="allMenuData.privilege" for="fullPrivilegeEdit" class="p-mt-2">Edit Menu Privileges</label>
                            <MultiSelect v-if="allMenuData.privilege != null" v-model="addEditRole.menuPrivilegeItems" id="fullPrivilegeEdit" :options="allMenuData.privilege" optionLabel="label" optionGroupLabel="value" optionGroupChildren="items" />
                            <div v-if="allMenuData.privilege" class="sup_menu_given">
                                <h4>Active Menus Privilege</h4>
                                <ul>
                                    <li v-for="(privilege, i) of addEditRole.menuPrivilegeItems" :key="i">{{privilege.value}}</li>
                                </ul>
                                <span v-if="addEditRole.menuPrivilegeItems === ''">Assign Privileges</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-fluid">
                        <Button type="submit" label="Submit" class="btn-primary" />
                    </div>
                    <div :class="['sup_form_massage p-mt-3', addEditRole.editStatus === 200 ? 'success' : 'error']" v-if="addEditRole.editStatus !== ''">{{'Role has been updated ' + addEditRole.editMessage}}</div >
                </form>
            </Dialog>
            <!--- Edit Role Modal End --->
            <!--- Role Menu Edit Modal Start 'menuEditModal' --->
            <Dialog v-model:visible="roleModal.MenuModal" :style="[{width: '600px'}]" :modal="true" @hide="closeModal()">
                <template #header>
                    <h3>{{modalRoledata.value.description}} Menu and Privileges</h3>
                </template>
                <div class="p-field sup_menu_access_set">
                    <label for="menuEdit">Edit Menu</label>
                    <MultiSelect v-model="addEditRole.menuItems" id="menuEdit" :options="allMenuData.menu" optionLabel="name" placeholder="Add or Remove menu" @hide="updateRole(modalRoledata.value, 'menuEditChange'), fetchAllMenuPrivilege()" />
                    <div class="sup_menu_given">
                        <h4>Active Menus</h4>
                        <ul>
                            <li v-for="(menu, i) of addEditRole.menuItems" :key="i">{{menu.name}}</li>
                        </ul>
                        <span v-if="addEditRole.menuItems === ''">Assign Menu</span>
                    </div>
                    <label v-if="allMenuData.privilege" for="privilegeEdit" class="p-mt-2">Edit Menu Privileges</label>
                    <MultiSelect v-if="allMenuData.privilege != null" v-model="addEditRole.menuPrivilegeItems" id="privilegeEdit" :options="allMenuData.privilege" optionLabel="label" optionGroupLabel="value" optionGroupChildren="items" @hide="updateRole(modalRoledata.value, 'menuEditChange')" />
                    <div v-if="allMenuData.privilege" class="sup_menu_given">
                        <h4>Active Menus Privilege</h4>
                        <ul>
                            <li v-for="(privilege, i) of addEditRole.menuPrivilegeItems" :key="i">{{privilege.value}}</li>
                        </ul>
                        <span v-if="addEditRole.menuPrivilegeItems === ''">Assign Privileges</span>
                    </div>
                    <div :class="['sup_form_massage p-mt-3', addEditRole.editStatus === 200 ? 'success' : 'error']" v-if="addEditRole.editStatus !== ''">{{'Role Menu has been updated ' + addEditRole.editMessage}}</div >
                </div>
            </Dialog>
            <!--- Role Menu Edit Modal End --->
            <!--- Deactive/Restore Confirmation Start --->
            <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
                <div class="confirmation-content p-d-flex p-items-center">
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                    <span>Do you want to proceed?</span>
                </div>
                <template #footer>
                    <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                    <Button label="Yes" icon="pi pi-check" @click="deleteAndRestoreRole(confirmationModal.modaldata.data, confirmationModal.modaldata.load), confirmationModal.modalState = false" class="p-button-primary" />
                </template>
            </Dialog>
            <!--- Deactive/Restore Confirmation End --->
        </div>
    </div>
</template>

<script>
import { allRole, allMenu, allMenuPrivilege } from '../../../../config/appUrls';
import { apiKey } from '../../../../config/constant';
import userLogout from '../../../../helper/userLogout/userLogout';
import {currentMenuUuid} from '../../../../helper/pageActions/pageActions';
import { upperCase } from '../../../../helper/utility/utility';
import { reactive, onBeforeMount, computed } from 'vue';
import { useStore } from 'vuex';
import axios from "axios";

export default {
    name: 'RoleManagement',
    setup(){
        //Vuex store data call fucntion
        const store = useStore();

        //User privilages role, UUID and actions store
        const pagePrivilages = reactive({
            userRole: '',
            uuid: '',
            actions: ''
        });

        //All role component realted data store
        const allRoleData = reactive({
            data: null,
            status: null,
            loading: false,
            roleStatus: 'active',
            searchRole: null
        })

        //All component modal state
        const roleModal = reactive({
            menuModal: false,
            addRoleModal: false,
            editRoleModal: false
        });

        //Role add/edit input data storing states
        const addEditRole = reactive({
            roleUUID: '',
            roleName: '',
            roleDesc: '',
            menuItems: '',
            menuPrivilegeItems: '',
            isSubmitted: false,
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: '',
        });

        //All menu and action API receiving data stroing state
        const allMenuData = reactive({
            menu: null,
            privilege: null,
            status: null,
        })

        //Role add/edit form validation state
        const addEditvalidationError = reactive({
            rolenameEmpty: false,
            rolenameinvalid: false,
            roleDescError: false,
            roleMenuError: false,
        });

        //Modal role data
        const modalRoledata = reactive({});

        //Delete/restore item corfirmation state
        const confirmationModal = reactive({
            modalState: false,
            modaldata: ''
        });

        //Coditional page action state update to show action conditionally
        const roleAdd = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.rolemanagementAdd);
        const roleEdit = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.rolemanagementEdit);
        const roleDeactivate = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.rolemanagementDeactivate);
        const roleDeactivateList = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.rolemanagementDeactivateList);

        /**
         * Self calling fucntion on compement initiation
         */
        (async () => {
            let { userRole, menuActions } = await currentMenuUuid('RoleManagement');
            pagePrivilages.userRole = userRole;
            pagePrivilages.actions = menuActions;
        })();

        /**
         * On component get mounted require functions call
         */
        onBeforeMount( async() => {
            fetchAllRole('active');
            fetchAllMenu();
        });

        /**
         * Fetching all role from DB
         * intially fetching active roles but this fucntion user can get deactive role and filter roles by date and name
         */
        const fetchAllRole = async (rolestatus,load) => {
            //Toggle role status to fetch active/deactive role
            if(load === 'roleStatus'){
                allRoleData.roleStatus = rolestatus === 'active' ? 'de-active' : 'active' ;
            }

            //To clear search and fetch all data
            if(load === 'searchClear'){
                allRoleData.searchRole = null
            }
            try{
                allRoleData.loading = !allRoleData.loading;
                const response = await axios.get( allRole, {
                    params: {
                        status: allRoleData.roleStatus,
                        searchBy: allRoleData.searchRole
                    }, 
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allRoleData.data = response.data.data;
                allRoleData.loading = !allRoleData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    //If unauthorized then logout admin portal
                    userLogout();
                } else {
                    console.log('error', err.message);
                }
            }
        }

        /**
         * Fetch all menu from DB
         */
        const fetchAllMenu = async () => {
            const response = await axios.get( allMenu, {
                    headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                //store menu list in local variable
                allMenuData.menu = response.data.data;
            }
        }

        /**
         * Fetching all available actions on respective menu by menu UUID
         */
        const fetchAllMenuPrivilege = async () => {
            //addEditRole.menuPrivilegeItems = null;
            let UUID = [] ;
            for (const key in addEditRole.menuItems) {
                UUID.push(addEditRole.menuItems[key].uuid);
            }
            const response = await axios.post( allMenuPrivilege,{
                uuid: UUID
            }, {
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                //store action list in local variable
                allMenuData.privilege = response.data.data;
            }
        }

        /**
         * Creating new role by setting menu and active authorization and Updating to DB
         */
        const createRole = async () => {
            let isvalidate = fromValidation();
            if (isvalidate) {
                addEditRole.isSubmitted = true;
                let NewMenu = {};
                let NewPrivileges = [];
                for (const key in addEditRole.menuItems) {
                    NewMenu[addEditRole.menuItems[key].name] = addEditRole.menuItems[key].uuid;
                    let tempArr = [];
                    for (const key2 in addEditRole.menuPrivilegeItems) { 
                        if(addEditRole.menuItems[key].uuid === addEditRole.menuPrivilegeItems[key2].uuid){
                            tempArr.push({
                                [addEditRole.menuPrivilegeItems[key2].value] : true
                            });
                        }
                    }
                    NewPrivileges.push({
                        uuid: addEditRole.menuItems[key].uuid,
                        action : tempArr
                    })
                }
                

                //API CALL
                try{
                    const response = await axios.post( allRole, {
                        role: addEditRole.roleName,
                        description: upperCase(addEditRole.roleDesc),
                        menuAccess: NewMenu,
                        menuPrivileges: NewPrivileges
                    }, { headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken,
                        },
                    });
                    if(response.status === 200) {
                        addEditRole.addStatus = response.data.status;
                        addEditRole.addMessage = response.data.data;
                        fetchAllRole('active');
                        addEditRoleFormReset();
                    }
                } catch(err){
                    console.log('err', err);
                }
            }
        }

        //Edit Role and Menu modals data binding
        const editRole = (sloteData, state) => {
            let filteredMenu = [];
            for (const key in sloteData.menuAccess) {
                filteredMenu.push({
                    name: key,
                    uuid: sloteData.menuAccess[key]
                })
            }

            let filteredPrivileges = [];
            for (let i = 0; i < sloteData.menuPrivileges.length; i++) {
                let menuAction = sloteData.menuPrivileges[i].action;                
                let menuUuid = sloteData.menuPrivileges[i].uuid;                
                for (let j = 0; j < menuAction.length; j++) {
                    let keys = Object.keys(menuAction[j]);  
                    let newLabel = keys[0].split(/(?=[A-Z])/);
                    filteredPrivileges.push({
                        label: newLabel.length > 2 ? newLabel[1]+newLabel[2] : newLabel[1],
                        uuid: menuUuid,
                        value: keys[0],
                    })                   
                }              
            }


            // let filteredPrivileges = [];
            // for (const key in sloteData.menuPrivileges) {
            //     let newLabel = key.split(/(?=[A-Z])/);
            //     filteredPrivileges.push({
            //         label: newLabel[1],
            //         value: key
            //     })
            // }

            //For Role and Menu edit
            if (state === 'fullRoleEdit') {
                roleModal.editRoleModal = true;
                addEditRole.roleUUID = sloteData.uuid;
                addEditRole.roleName = sloteData.role;
                addEditRole.roleDesc = sloteData.description;
                addEditRole.menuItems = filteredMenu;
                fetchAllMenuPrivilege();
                addEditRole.menuPrivilegeItems = filteredPrivileges;
                modalRoledata.value = sloteData;
            }

            //For only Menu edit
            if (state === 'roleMenuEdit') {
                addEditRole.menuItems = filteredMenu;
                fetchAllMenuPrivilege();
                addEditRole.menuPrivilegeItems = filteredPrivileges;
                // getApprovedMenu.value = filteredMenu;
                modalRoledata.value = sloteData;
                roleModal.MenuModal = true;
            }
        }

        //Update edited Role
        const updateRole = async (roledata, state) => {
            let NewMenu = {};
            let NewPrivileges = [];
            let fullEditSubmit = false;
            let menuEditchange = false;

            //full role edit final process
            if (state === 'fullEditsubmit') {
                addEditRole.isSubmitted = fromValidation();
                if (addEditRole.isSubmitted) {
                    for (const key in addEditRole.menuItems) {
                        NewMenu[addEditRole.menuItems[key].name] = addEditRole.menuItems[key].uuid;
                        let tempArr = [];
                        for (const key2 in addEditRole.menuPrivilegeItems) { 
                            if(addEditRole.menuItems[key].uuid === addEditRole.menuPrivilegeItems[key2].uuid){
                                tempArr.push({
                                    [addEditRole.menuPrivilegeItems[key2].value] : true
                                });
                            }
                        }
                        NewPrivileges.push({
                            uuid: addEditRole.menuItems[key].uuid,
                            action : tempArr
                        })
                    }
                    fullEditSubmit = true
                }
            }

            //Only for old And new edited menu match
            if (state === 'menuEditChange' && roledata !== null) {
                // let oldMenu = roledata.menuAccess DO NOT DELETE THESE
                // let oldPrivileges = roledata.menuPrivileges
                for (const key in addEditRole.menuItems) {
                    NewMenu[addEditRole.menuItems[key].name] = addEditRole.menuItems[key].uuid;
                    let tempArr = [];
                    for (const key2 in addEditRole.menuPrivilegeItems) { 
                        if(addEditRole.menuItems[key].uuid === addEditRole.menuPrivilegeItems[key2].uuid){
                            tempArr.push({
                                [addEditRole.menuPrivilegeItems[key2].value] : true
                            });
                        }
                    }
                    NewPrivileges.push({
                        uuid: addEditRole.menuItems[key].uuid,
                        action : tempArr
                    })
                }


                //Menu compare
                //JSON.stringify(oldMenu) === JSON.stringify(NewMenu)
                let isNotMatch = false;
                if (!isNotMatch) {
                    menuEditchange = true;
                    addEditRole.roleUUID = roledata.uuid;
                    addEditRole.roleDesc = roledata.description;
                }
            }

            //Final Update API call
            if (fullEditSubmit || menuEditchange) {
                try{
                    const options = {
                        method: 'put',
                        url: allRole,
                        params: {
                            uuid: addEditRole.roleUUID
                        },
                        data: JSON.stringify({
                            description: upperCase(addEditRole.roleDesc),
                            menuAccess: NewMenu,
                            menuPrivileges: NewPrivileges
                        }),
                        headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                            // transform the data
                            return data;
                        }]
                    };
                    let response = await axios(options);
                    addEditRole.roleName = response.data.data.role;
                    addEditRole.roleDesc = response.data.data.description;
                    addEditRole.editStatus = response.data.status;
                    addEditRole.editMessage = response.data.message;
                    fetchAllRole('active');
                } catch(err){
                    console.log('err', err);
                }
            }
        }

        //Form validation
        const fromValidation = () => {
            let hasSpace = addEditRole.roleName.indexOf(' ') >= 0;
            if (!addEditRole.roleName) {
                addEditvalidationError.rolenameEmpty = true;
            } else if (addEditRole.roleName && hasSpace){
                addEditvalidationError.rolenameEmpty = false;
                addEditvalidationError.rolenameinvalid = true;
            } 
            else {
                addEditvalidationError.rolenameError = false;
            }

            if (!addEditRole.roleDesc) {
                addEditvalidationError.roleDescError = true;
            } else {
                addEditvalidationError.roleDescError = false;
            }

            if (Object.keys(addEditRole.menuItems).length === 0) {
                addEditvalidationError.roleMenuError = true;
            } else {
                addEditvalidationError.roleMenuError = false;
            }

            if (!addEditvalidationError.rolenameError && !addEditvalidationError.roleDescError && !addEditvalidationError.roleMenuError) {
                return true;
            } else {
                return false;
            }
        }

        //Form reset
        const addEditRoleFormReset = () => {
            addEditRole.roleUUID = '';
            addEditRole.roleName = '';
            addEditRole.roleDesc = '';
            addEditRole.menuItems = '';
            addEditRole.menuPrivilegeItems = '';
            addEditRole.isSubmitted = false;
        }

        //All modal after close function call
        const closeModal = () => {
            addEditRoleFormReset();
            addEditRole.addStatus = '';
            addEditRole.addMessage = '';
            addEditRole.editStatus = '';
            addEditRole.editMessage = '';
        }

        /**
         * Delete or Restore Role's API call
         */
        const deleteAndRestoreRole = async (sloteData, type) => {
            try{
                const options = {
                    method: type === 'delete' ? 'delete' : 'patch',
                    url: allRole,
                    params: {
                        uuid: sloteData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllRole('active');
            } catch(err){
                console.log(err);
            }
        }

        /**
         * menu change function
         */
        const getchangedMenu = () => {
            let NewMenu = {};
            for (const key in addEditRole.menuItems) {
                NewMenu[addEditRole.menuItems[key].name] = addEditRole.menuItems[key].uuid;
            }
        }

        /**
         * Delete/Restore confirmation
         */
        const confimationModal = (sloteData, load) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                load: load
            }
        }

        //value return
        return{
            roleAdd,
            roleEdit,
            roleDeactivate,
            roleDeactivateList,
            pagePrivilages,
            allRoleData,
            roleModal,
            addEditRole,
            allMenuData,
            addEditvalidationError,
            modalRoledata,
            confirmationModal,
            fetchAllRole,
            fetchAllMenuPrivilege,
            createRole,
            editRole,
            updateRole,
            closeModal,
            deleteAndRestoreRole,
            getchangedMenu,
            confimationModal
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/component/superAdmin/pages/rolesPrivileges/roleManagement.scss";
</style>